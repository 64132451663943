import { Button, Text, Table, TableBody, TableCell, TableHead, TableRow, View } from "@aws-amplify/ui-react";
import React from "react";
import { apiTradeOcoParametersGetResults } from "../api/trades";

const styles = {
    container: { width: 400, margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
    table: { margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
    button: { backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' }
}

class MonitorOcoParameters extends React.Component {  
    constructor(props){
        super(props)
        this.state = {
            elements: [],
            lastModifiedDate: ""
        }
    }  

    moveToHome() {        
        const homeUrl = `${window.location.origin}/`
        window.location.assign(homeUrl)
    }

    async componentDidMount() {
        const response = await apiTradeOcoParametersGetResults()
        if(!response.error){
            const elements = response.payload.elements
            this.setState({lastModifiedDate: response.payload.lastModifiedDate, elements: elements})
        }
    }

    render() {
        return (
            <>
            <View style={styles.container}>
                <Button style={styles.button} onClick={this.moveToHome}>Home</Button> 
                <Text>{this.state.lastModifiedDate} (UTC)</Text>         
            </View>
            <View style={styles.table}>
                <Table caption="" highlightOnHover={true}>
                    <TableHead>
                        <TableRow>
                            <TableCell as="th">Symbol</TableCell>
                            <TableCell as="th">Price</TableCell>
                            <TableCell as="th">Change Percentage</TableCell>
                            <TableCell as="th">Price Limit</TableCell>
                            <TableCell as="th">Stop Limit</TableCell>
                            <TableCell as="th">Stop</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                        this.state.elements.map((item,index) => {
                        return (
                            <TableRow key={index}>
                                <TableCell>{item.symbol}</TableCell>
                                <TableCell>{item.price}</TableCell>
                                <TableCell>{item.changePercentage}</TableCell>
                                <TableCell>{item.priceLimit}</TableCell>
                                <TableCell>{item.stopLimit}</TableCell>
                                <TableCell>{item.stop}</TableCell>
                            </TableRow>
                        );
                    })} 
                    </TableBody>
                </Table>  
            </View>
            </>
        )
    }
}

export default MonitorOcoParameters