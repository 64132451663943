import { Loader } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import React from "react";

class Logout extends React.Component {   

    logout() {
        Auth.signOut({ global: true }).then(() => {
            const loginUrl = window.location.origin
            window.location.assign(loginUrl)
        })
    }

    componentDidMount() {
        setTimeout(() => {
            console.log('logout timeout')
            this.logout()
        }, 1000)
    }

    render() {
        return (
            <div>
                <Loader true />
            </div>
        )
    }
}

export default Logout