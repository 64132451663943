import { Button, Divider, Flex, Heading, Loader, SelectField, TextField, View } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import React from "react";
import { apiTriangulationCheck, apiTriangulationGetLiquidityProgress, apiTriangulationConsumer, apiTriangulationExecute, apiTriangulationRecognizeLiquidityShitSymbols, apiTriangulationReset, apiGetProgress, apitradeTestOcoParameters } from "../api/trades";

const styles = {
    container: { width: 1200, margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
    button: { backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' }
}

const THRESHOLD_DEFAULT_VALUE = 3000000000
const TIME_RANGE_DEFAULT_VALUE = 24
const OCO_PARAMETERS_DEFAULT_SYMBOL = "BTCBUSD"
const OCO_PARAMETERS_DEFAULT_CHANGE_RATE = 0
const OCO_PARAMETERS_DEFAULT_PRICE_DELTA = 0
const OCO_PARAMETERS_DEFAULT_SIDE = "BUY"

class HomeDeprecated extends React.Component { 

    constructor(props){
        super(props)
        this.state = {
            triangulationWorking: false,
            liquidityWorking: false,
            ocoParametersWorking: false,
            liquidityRecognizeProgress: 0,
            ocoParametersProgress: 0,
            liquidityThreshold: THRESHOLD_DEFAULT_VALUE,
            liquidityTimeRange: TIME_RANGE_DEFAULT_VALUE,
            ocoParametersSymbol: OCO_PARAMETERS_DEFAULT_SYMBOL,
            ocoParametersChangeRate: OCO_PARAMETERS_DEFAULT_CHANGE_RATE,
            ocoParametersPriceDelta: OCO_PARAMETERS_DEFAULT_PRICE_DELTA,
            ocoParametersSide: OCO_PARAMETERS_DEFAULT_SIDE
        }
        this.launchTriangulationAnalysis = this.launchTriangulationAnalysis.bind(this);
        this.launchLiquidityAnalysis = this.launchLiquidityAnalysis.bind(this);
        this.launchTestOcoParameters = this.launchTestOcoParameters.bind(this);
        this.onLiquidityThresholdChange = this.onLiquidityThresholdChange.bind(this);
        this.onLiquidityTimeRangeChange = this.onLiquidityTimeRangeChange.bind(this);
        this.onOcoParametersSymbolChange = this.onOcoParametersSymbolChange.bind(this);
        this.onOcoParametersChangeRateChange = this.onOcoParametersChangeRateChange.bind(this);
        this.onOcoParametersPriceDeltaChange = this.onOcoParametersPriceDeltaChange.bind(this);
        this.onOcoParametersSideChange = this.onOcoParametersSideChange.bind(this);        
        this.liquidityProgressHandler = null
        this.ocoParametersProgressHandler = null
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async launchTriangulationAnalysis() {
        let response = await apiTriangulationExecute(this.state.liquidityThreshold, this.state.liquidityTimeRange)
        if(!response.error){
            console.log('Analysis launched')
            this.setState({triangulationWorking: true})
            response = await apiTriangulationReset()
            console.log('Reset done')
            setInterval(async () => {
                console.log('Checking...')
                this.checkTriangulationWorking()
            }, 30000)
        }
    }

    async launchLiquidityAnalysis() {
        let response = await apiTriangulationRecognizeLiquidityShitSymbols(this.state.liquidityThreshold, this.state.liquidityTimeRange)
        if(!response.error){
            await this.sleep(2)
            this.startLiquidityChecking()
        }
    }

    async launchTestOcoParameters() {
        let response = await apitradeTestOcoParameters(this.state.ocoParametersSymbol, this.state.ocoParametersChangeRate, 
            this.state.ocoParametersPriceDelta, this.state.ocoParametersSide)
        if(!response.error){
            await this.sleep(2)
            this.startOcoParametersChecking()
        }
    }

    moveToTriangulationMonitor() {
        const monitorUrl = `${window.location.origin}/v1/monitor`
        window.open(monitorUrl, '_blank', 'noreferrer')
    }

    moveToLiquidityMonitor() {
        const monitorUrl = `${window.location.origin}/deprecated/monitor-liquidity`
        window.open(monitorUrl, '_blank', 'noreferrer')
    }

    moveToOcoParametersMonitor() {
        const monitorUrl = `${window.location.origin}/deprecated/monitor-oco-parameters`
        window.open(monitorUrl, '_blank', 'noreferrer')
    }

    async signOut () {
        Auth.signOut({ global: true })
        .then(() => {

        })
        .catch((err) => {
            console.log(err)
        })
    }

    async componentDidMount() {
        this.checkTriangulationWorking()
        if(this.liquidityProgressHandler === null){
            this.startLiquidityChecking()
        }
        if(this.ocoParametersProgressHandler === null){
            this.startOcoParametersChecking()
        }        
    }

    startLiquidityChecking () {
        this.liquidityProgressHandler = setInterval(() => {
            this.checkLiquidityProgress()
        }, 1000)
    }


    startOcoParametersChecking () {
        this.ocoParametersProgressHandler = setInterval(() => {
            this.checkOcoParametersProgress()
        }, 1000)
    }

    async checkTriangulationWorking () {
        const response = await apiTriangulationCheck()
        this.setState({triangulationWorking: response.error})
    }

    async checkLiquidityProgress () {
        const response = await apiTriangulationGetLiquidityProgress()
        const recognizeProgress = response.payload.recognizeProgress === -1 ? 100 : response.payload.recognizeProgress
        const completed = recognizeProgress === 100
        this.setState({
            liquidityRecognizeProgress: recognizeProgress,
            liquidityWorking: !completed
        })
        if(completed && this.liquidityProgressHandler !== null){
            clearInterval(this.liquidityProgressHandler);
            this.liquidityProgressHandler = null
        }
    }

    async checkOcoParametersProgress () {
        const response = await apiGetProgress("test-oco-parameters")
        const progress = response.payload.progress === -1 ? 100 : response.payload.progress
        const completed = progress === 100
        this.setState({
            ocoParametersProgress: progress,
            ocoParametersWorking: !completed
        })
        if(completed && this.ocoParametersProgressHandler !== null){
            clearInterval(this.ocoParametersProgressHandler);
            this.ocoParametersProgressHandler = null
        }
    }

    onLiquidityThresholdChange (event) {
        let threshold = parseFloat(event.currentTarget.value)
        if(isNaN(threshold)){
            threshold = THRESHOLD_DEFAULT_VALUE
        }
        this.setState({liquidityThreshold: threshold})
    }

    onLiquidityTimeRangeChange (event) {
        let timeRange = parseFloat(event.currentTarget.value)
        if(isNaN(timeRange)){
            timeRange = TIME_RANGE_DEFAULT_VALUE
        }
        this.setState({liquidityTimeRange: timeRange})
    }    

    onOcoParametersSymbolChange (event) {
        this.setState({ocoParametersSymbol: event.currentTarget.value})
    }

    onOcoParametersChangeRateChange (event) {
        let changeRate = parseFloat(event.currentTarget.value)
        if(isNaN(changeRate)){
            changeRate = OCO_PARAMETERS_DEFAULT_CHANGE_RATE
        }
        this.setState({ocoParametersChangeRate: changeRate})
    }

    onOcoParametersPriceDeltaChange (event) {
        let priceDelta = parseFloat(event.currentTarget.value)
        if(isNaN(priceDelta)){
            priceDelta = OCO_PARAMETERS_DEFAULT_PRICE_DELTA
        }
        this.setState({ocoParametersPriceDelta: priceDelta})
    }

    onOcoParametersSideChange (event) {
        this.setState({ocoParametersSide: event.currentTarget.value})
    }

    render() {
        return (
            <View style={styles.container}>
                <Flex direction="column">
                    <Heading level={1}>Hello {this.props.user.attributes.given_name} {this.props.user.attributes.family_name}</Heading>
                    <Divider orientation="horizontal" />
                    <Divider orientation="horizontal" />
                    <SelectField label="Side" options={['BUY', 'SELL']} onChange={this.onOcoParametersSideChange}></SelectField>
                    <TextField placeholder="BTCBUSD" label="Symbol" onChange={this.onOcoParametersSymbolChange} />
                    <TextField placeholder="0" label="Change Rate" onChange={this.onOcoParametersChangeRateChange} />
                    <TextField placeholder="0" label="Price Delta" onChange={this.onOcoParametersPriceDeltaChange} />
                    <Button style={styles.button} disabled={this.state.ocoParametersWorking} onClick={this.launchTestOcoParameters}>Test Oco Parameters</Button>
                    <Loader variation="linear" percentage={this.state.ocoParametersProgress} isDeterminate />
                    <Button style={styles.button} onClick={this.moveToOcoParametersMonitor}>Monitor Oco Parameters</Button>
                    <Divider orientation="horizontal" />
                    <Divider orientation="horizontal" />
                    <TextField placeholder="3000000000" label="Liquidity Threshold" onChange={this.onLiquidityThresholdChange} />
                    <TextField placeholder="24" label="Liquidity Time Range (hours)" onChange={this.onLiquidityTimeRangeChange} />
                    <Button style={styles.button} disabled={this.state.liquidityWorking} onClick={this.launchLiquidityAnalysis}>Launch Liquidity Analysis</Button>
                    <Loader variation="linear" percentage={this.state.liquidityRecognizeProgress} isDeterminate />
                    <Button style={styles.button} onClick={this.moveToLiquidityMonitor}>Monitor Liquidity Values</Button>
                    <Divider orientation="horizontal" />
                    <Divider orientation="horizontal" />
                    <Button style={styles.button} disabled={this.state.triangulationWorking} onClick={this.launchTriangulationAnalysis}>Launch Triangulation Analysis</Button>
                    <Button style={styles.button} onClick={this.moveToTriangulationMonitor}>Monitor Triangulation Values</Button>
                    <Divider orientation="horizontal" />
                    <Divider orientation="horizontal" />
                    <Button style={styles.button} onClick={this.signOut}>Sign out</Button>
                </Flex>                
            </View>
        )
    }
}

export default HomeDeprecated