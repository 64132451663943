const baseUrl = 'https://api.leggierimatteo.com/binance'

export const endpoints = {
  triangulationExecute: `${baseUrl}/triangulation-execute`,
  triangulationConsumer: `${baseUrl}/triangulation-consumer`,
  triangulationCheck: `${baseUrl}/triangulation-check`,
  triangulationReset: `${baseUrl}/triangulation-reset`,
  triangulationGetResults: `${baseUrl}/triangulation-get-results`,
  triangulationGetListResults: `${baseUrl}/triangulation-get-list-results`,
  triangulationRecognizeLiquidityShitSymbols: `${baseUrl}/triangulation-recognize-liquid-shit-symbols`,
  triangulationGetLiquidityProgress: `${baseUrl}/triangulation-get-liquidity-progress`,
  triangulationResetLiquidity: `${baseUrl}/triangulation-reset-liquidity`,
  triangulationGetLiqudityResults: `${baseUrl}/triangulation-get-liquidity-results`,
  getProgress: `${baseUrl}/get-progress`,
  tradeTestOcoParameters: `${baseUrl}/trade-test-oco-parameters`,
  tradeOcoParametersGetResults: `${baseUrl}/trade-oco-parameters-get-results`,
  reportShitLiquidSymbolsGetTriangulation: `${baseUrl}/report-shit-liquid-symbols-get-triangulation`,
  reportShitLiquidSymbolsListTriangulation: `${baseUrl}/report-shit-liquid-symbols-list-triangulation`,
  reportShitLiquidSymbolsListMagic: `${baseUrl}/report-shit-liquid-symbols-list-magic`
}