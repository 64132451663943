import React from 'react'
import { Amplify } from 'aws-amplify'
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsExports from "./aws-exports";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import Logout from './pages/logout';
import MonitorLiquidty from './pages/monitor-liquidty';
import MonitorOcoParameters from './pages/monitor-oco-parameters';
import HomeDeprecated from './pages/home_deprecated';
import MonitorV1 from './pages/monitor_v1';
import HomeV1 from './pages/home_v1';
import HomeMagic from './pages/home_magic';
import MonitorMagic from './pages/monitor_magic';
import Opportunity from './pages/opportunity';
import Trade from './pages/trade';
Amplify.configure(awsExports);

const App = ({ signOut, user }) => {

  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/logout" element={<Logout />} />
          <Route path="/" element={<Home user={user}/>} />
          <Route path="/opportunity/:version/:year/:month/:day/:file" element={<Opportunity />} />
          <Route path="/trade/:version/Results/:year/:month/:day/:file" element={<Trade />} />
          <Route path="/magic/" element={<HomeMagic user={user}/>} />
          <Route path="/magic/monitor/:version/:year/:month/:day/:file" element={<MonitorMagic />} />
          <Route path="/v1/" element={<HomeV1 user={user}/>} />
          <Route path="/v1/monitor/:version/:year/:month/:day/:file" element={<MonitorV1 />} />
          <Route path="/deprecated/" element={<HomeDeprecated user={user}/>} />
          <Route path="/deprecated/monitor-liquidity" element={<MonitorLiquidty />} />  
          <Route path="/deprecated/monitor-oco-parameters" element={<MonitorOcoParameters />} />          
        </Routes>
      </div>
    </BrowserRouter>
  )
}

export default withAuthenticator(App, { hideSignUp: true });