import { Link, Loader, View, Text, useTheme, Heading } from "@aws-amplify/ui-react";
import React from "react";
import { apiReportShitLiquidSymbolsListTriangulation } from "../api/trades";

const styles = {
    container: { width: 400, margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
    table: { margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
    button: { backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' }
}

class Home extends React.Component { 

    constructor(props){
        super(props)
        this.state = {
            files: {},
            loading: true
        }
    }  

    async componentDidMount() {
        this.setState({loading: true})
        const response = await apiReportShitLiquidSymbolsListTriangulation(30)
        this.setState({loading: false})
        if(!response.error){
            this.setState({files: JSON.parse(response.payload.body)})
        }
    }

    render() {  
        let content = ""
        if(this.state.files && Object.keys(this.state.files).length>0) {
            content = Object.keys(this.state.files).map((day, dayIndex) => 
                <View style={styles.table} key={dayIndex}>
                    <>
                    <Heading key={day} level={5} color="#043495">{day}</Heading>                    
                    {
                        this.state.files[day]['trades']?.map((trade, index) =>
                            <Link key={trade} color="#007EB9" href={`${window.location.origin}/trade/${trade}`} isExternal={true}>{trade}</Link>
                        )
                    } 
                    </>
                </View>
            )
        }
        return (
            <>
            <View style={styles.table}>
            <Loader variation="linear" display={this.state.loading ? 'block' : 'none'}></Loader>
            {content}  
            </View>
            </>
        )
    }
}

export default Home