import { Button, Text, Table, TableBody, TableCell, TableRow, View, ThemeProvider, Loader } from "@aws-amplify/ui-react";
import React from "react";
import { useParams } from "react-router-dom";
import { apiReportShitLiquidSymbolsGetTriangulation } from "../api/trades";
import { toLinearString } from "../utils/numbers";
import '../index.css';

const styles = {
    container: { width: 400, margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
    table: { margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
    button: { backgroundColor: '#043495', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' }
}

const theme = {
    name: 'table-theme',
    tokens: {
      components: {
        table: {
          row: {
            hover: {
              backgroundColor: { value: '{colors.blue.20}' },
            },
            striped: {
              backgroundColor: { value: '{colors.blue.10}' },
            },
          },
          header: {
            backgroundColor: { value: '{colors.background.info}' },
            color: { value: '{colors.blue.80}' },
            fontSize: { value: '{fontSizes.1}' }
          },  
          data: {
            fontWeight: { value: '{fontWeights.semibold}' },
          },
        },
      },
    },
  };

export function withRouter(Children){
    return(props)=>{
       const match  = {params: useParams()};
       return <Children {...props}  match = {match}/>
   }
 }

class Trade extends React.Component {    
    constructor(props){
        super(props)
        this.state = {
            elements: [],
            lastModifiedDate: "",
            loading: true
        }        
    }      

    moveToHome() {        
        const homeUrl = `${window.location.origin}/`
        window.location.assign(homeUrl)
    }

    async componentDidMount() {
        this.setState({loading: true})
        const filepath = this.props.match.params.version + '/Results/' + this.props.match.params.year + "/" + this.props.match.params.month + "/" 
                + this.props.match.params.day + "/" + this.props.match.params.file
        let response = await apiReportShitLiquidSymbolsGetTriangulation(filepath)
        this.setState({loading: false})
        if(!response.error){
            response = JSON.parse(response.payload.body)
            console.log(response)
            this.setState({lastModifiedDate: response.lastModifiedDate, elements: [response.elements]})
        }
    }

    render() {
        return (
            <>
            <View style={styles.container}>
                <Button style={styles.button} onClick={this.moveToHome}>Home</Button>       
                <Text color="#043495" className="text_datetime">{this.state.lastModifiedDate}</Text>  
            </View>
            <View style={styles.table}>
                <ThemeProvider theme={theme} colorMode="light">
                    <Table highlightOnHover variation="bordered">
                        <TableBody>
                        <>
                            <TableRow key="itemsHeaders">
                                <TableCell as="th" colspan="3">First Trade</TableCell>
                                <TableCell as="th" colspan="3" className="column_divider">Second Trade</TableCell>
                                <TableCell as="th" colspan="3" className="column_divider">Third Trade</TableCell>
                                <TableCell as="th" colspan="2" rowspan="2" className="column_divider table_header">Triangulation</TableCell>
                            </TableRow>
                            <TableRow key="commonHeaders">
                                <TableCell as="th">Symbol</TableCell>
                                <TableCell as="th">Operation</TableCell>
                                <TableCell as="th">Trade Type</TableCell>
                                <TableCell as="th" className="column_divider">Symbol</TableCell>
                                <TableCell as="th">Operation</TableCell>
                                <TableCell as="th">Trade Type</TableCell>
                                <TableCell as="th" className="column_divider">Symbol</TableCell>
                                <TableCell as="th">Operation</TableCell>
                                <TableCell as="th">Trade Type</TableCell>
                            </TableRow>
                            {
                            this.state.elements.map((item,index) => {
                            return (
                                <>
                                <TableRow key={index + "_commonValues"}>
                                    <TableCell>{item.triangulations[0].common.symbol}</TableCell>
                                    <TableCell>{item.triangulations[0].trade.operation}</TableCell>
                                    <TableCell>{item.triangulations[0].trade.type}</TableCell>
                                    <TableCell className="column_divider">{item.triangulations[1].common.symbol}</TableCell>
                                    <TableCell>{item.triangulations[1].trade.operation}</TableCell>
                                    <TableCell>{item.triangulations[1].trade.type}</TableCell>
                                    <TableCell className="column_divider">{item.triangulations[2].common.symbol}</TableCell>
                                    <TableCell>{item.triangulations[2].trade.operation}</TableCell>
                                    <TableCell>{item.triangulations[2].trade.type}</TableCell>
                                    <TableCell as="th" className="column_divider">Recognizer</TableCell>
                                    <TableCell>{item.recognizedBy}</TableCell>
                                    
                                </TableRow>
                                <TableRow key="paramsHeaders">
                                    <TableCell as="th">Params</TableCell>
                                    <TableCell as="th">Trade</TableCell>
                                    <TableCell as="th">Opportunity</TableCell>
                                    <TableCell as="th" className="column_divider">Params</TableCell>
                                    <TableCell as="th">Trade</TableCell>
                                    <TableCell as="th">Opportunity</TableCell>
                                    <TableCell as="th" className="column_divider">Params</TableCell>
                                    <TableCell as="th">Trade</TableCell>
                                    <TableCell as="th">Opportunity</TableCell>                                    
                                    <TableCell as="th" className="column_divider">Change Value</TableCell>
                                    <TableCell>{item.changePercent}</TableCell>
                                </TableRow>
                                <TableRow key={index  + "_priceValues"}>
                                    <TableCell as="th">Price</TableCell>
                                    <TableCell>{toLinearString(item.triangulations[0].trade.price)}</TableCell>
                                    <TableCell>{toLinearString(item.triangulations[0].sourceOcoParams?.price)}</TableCell>                                    
                                    <TableCell as="th" className="column_divider">Price</TableCell>
                                    <TableCell>{toLinearString(item.triangulations[1].trade.price)}</TableCell>
                                    <TableCell>{toLinearString(item.triangulations[1].sourceOcoParams?.price)}</TableCell>
                                    <TableCell as="th" className="column_divider">Price</TableCell>
                                    <TableCell>{toLinearString(item.triangulations[2].trade.price)}</TableCell>
                                    <TableCell>{toLinearString(item.triangulations[2].sourceOcoParams?.price)}</TableCell>
                                    <TableCell as="th" className="column_divider">Trade Gain (%)</TableCell>
                                    <TableCell>{toLinearString(item.tradeDiffPercentage)}</TableCell>                                    
                                </TableRow>
                                <TableRow key={index  + "_priceLimitValues"}>
                                    <TableCell as="th">Price Limit</TableCell>
                                    <TableCell>{toLinearString(item.triangulations[0].ocoParams.priceLimit)}</TableCell>
                                    <TableCell>{toLinearString(item.triangulations[0].sourceOcoParams?.priceLimit)}</TableCell>                                    
                                    <TableCell as="th" className="column_divider">Price Limit</TableCell>
                                    <TableCell>{toLinearString(item.triangulations[1].ocoParams.priceLimit)}</TableCell>
                                    <TableCell>{toLinearString(item.triangulations[1].sourceOcoParams?.priceLimit)}</TableCell>
                                    <TableCell as="th" className="column_divider">Price Limit</TableCell>
                                    <TableCell>{toLinearString(item.triangulations[2].ocoParams.priceLimit)}</TableCell>
                                    <TableCell>{toLinearString(item.triangulations[2].sourceOcoParams?.priceLimit)}</TableCell>
                                    <TableCell as="th" className="column_divider">Price Gain (%)</TableCell>
                                    <TableCell>{toLinearString(item.diffPercentage)}</TableCell>
                                </TableRow>
                                <TableRow key={index  + "_stopLimitValues"}>
                                    <TableCell as="th">Stop Limit</TableCell>
                                    <TableCell>{toLinearString(item.triangulations[0].ocoParams.stopLimit)}</TableCell>
                                    <TableCell>{toLinearString(item.triangulations[0].sourceOcoParams?.stopLimit)}</TableCell>                                    
                                    <TableCell as="th" className="column_divider">Stop Limit</TableCell>
                                    <TableCell>{toLinearString(item.triangulations[1].ocoParams.stopLimit)}</TableCell>
                                    <TableCell>{toLinearString(item.triangulations[1].sourceOcoParams?.stopLimit)}</TableCell>
                                    <TableCell as="th" className="column_divider">Stop Limit</TableCell>
                                    <TableCell>{toLinearString(item.triangulations[2].ocoParams.stopLimit)}</TableCell>
                                    <TableCell>{toLinearString(item.triangulations[2].sourceOcoParams?.stopLimit)}</TableCell>
                                    <TableCell as="th" className="column_divider">Best Gain (%)</TableCell>
                                    <TableCell>{toLinearString(item.bestCaseDiffPercentage)}</TableCell>
                                </TableRow>
                                <TableRow key={index  + "_stopValues"}>
                                    <TableCell as="th">Stop</TableCell>
                                    <TableCell>{toLinearString(item.triangulations[0].ocoParams.stop)}</TableCell>
                                    <TableCell>{toLinearString(item.triangulations[0].sourceOcoParams?.stop)}</TableCell>                                    
                                    <TableCell as="th" className="column_divider">Stop</TableCell>
                                    <TableCell>{toLinearString(item.triangulations[1].ocoParams.stop)}</TableCell>
                                    <TableCell>{toLinearString(item.triangulations[1].sourceOcoParams?.stop)}</TableCell>
                                    <TableCell as="th" className="column_divider">Stop</TableCell>
                                    <TableCell>{toLinearString(item.triangulations[2].ocoParams.stop)}</TableCell>
                                    <TableCell>{toLinearString(item.triangulations[2].sourceOcoParams?.stop)}</TableCell>
                                    <TableCell as="th" className="column_divider">Worst Gain (%)</TableCell>
                                    <TableCell>{toLinearString(item.worstCaseDiffPercentage)}</TableCell>
                                </TableRow>                                
                                </>
                            );
                        })} 
                        </>
                        </TableBody>
                    </Table>  
                </ThemeProvider>                
            </View>            
            <Loader variation="linear" display={this.state.loading ? 'block' : 'none'}></Loader>
            </>
        )
    }
}

 export default withRouter(Trade)
 