import ApiClient from "../services/apiClient"
import { endpoints } from "./config"

export const apiTriangulationExecute = async (liquidityThreshold, liquidityTimeRange) => {
    const client = new ApiClient()
    return await client.doRequest(endpoints.triangulationExecute, 'GET', {liquidityThreshold: liquidityThreshold, liquidityTimeRange: liquidityTimeRange})
}

export const apiTriangulationConsumer = async () => {
    const client = new ApiClient()
    return await client.doRequest(endpoints.triangulationConsumer)
}

export const apiTriangulationReset = async () => {
    const client = new ApiClient()
    return await client.doRequest(endpoints.triangulationReset)
}

export const apiTriangulationCheck = async () => {
    const client = new ApiClient()
    return await client.doRequest(endpoints.triangulationCheck)
}

export const apiTriangulationGetResults = async (filepath) => {
    const client = new ApiClient()
    return await client.doRequest(endpoints.triangulationGetResults, 'GET', {filepath: filepath})
}

export const apiTriangulationGetListResults = async (daysAgo) => {
    const client = new ApiClient()
    return await client.doRequest(endpoints.triangulationGetListResults, 'GET', {daysAgo: daysAgo})
}

export const apiTriangulationRecognizeLiquidityShitSymbols = async (liquidityThreshold, liquidityTimeRange) => {
    const client = new ApiClient()
    return await client.doRequest(endpoints.triangulationRecognizeLiquidityShitSymbols, 'GET', {liquidityThreshold: liquidityThreshold, liquidityTimeRange: liquidityTimeRange})
}

export const apiTriangulationResetLiquidity = async () => {
    const client = new ApiClient()
    return await client.doRequest(endpoints.triangulationResetLiquidity)
}

export const apiTriangulationGetLiquidityProgress = async () => {
    const client = new ApiClient()
    return await client.doRequest(endpoints.triangulationGetLiquidityProgress)
}

export const apiTriangulationGetLiquidityResults = async () => {
    const client = new ApiClient()
    return await client.doRequest(endpoints.triangulationGetLiqudityResults)
}

export const apiGetProgress = async (progressKey) => {
    const client = new ApiClient()
    return await client.doRequest(endpoints.getProgress, 'GET', {progressKey: progressKey})
}

export const apitradeTestOcoParameters = async (symbol, changeRate, priceDelta, side) => {
    const client = new ApiClient()
    return await client.doRequest(endpoints.tradeTestOcoParameters, 'GET', {symbol: symbol, changeRate: changeRate, priceDelta: priceDelta, side: side})
}

export const apiTradeOcoParametersGetResults = async () => {
    const client = new ApiClient()
    return await client.doRequest(endpoints.tradeOcoParametersGetResults)
}

export const apiReportShitLiquidSymbolsGetTriangulation = async (filepath) => {
    const client = new ApiClient()
    return await client.doRequest(endpoints.reportShitLiquidSymbolsGetTriangulation, 'GET', {filepath: filepath})
}

export const apiReportShitLiquidSymbolsListTriangulation = async (daysAgo) => {
    const client = new ApiClient()
    return await client.doRequest(endpoints.reportShitLiquidSymbolsListTriangulation, 'GET', {daysAgo: daysAgo})
}

export const apiReportShitLiquidSymbolsListMagic = async (daysAgo) => {
    const client = new ApiClient()
    return await client.doRequest(endpoints.reportShitLiquidSymbolsListMagic, 'GET', {daysAgo: daysAgo})
}