import { Button, Text, Table, TableBody, TableCell, TableHead, TableRow, View, ThemeProvider, Loader } from "@aws-amplify/ui-react";
import React from "react";
import { useParams } from "react-router-dom";
import { apiReportShitLiquidSymbolsGetTriangulation } from "../api/trades";
import { toLinearString } from "../utils/numbers";

const styles = {
    container: { width: 400, margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
    table: { margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
    button: { backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' }
}

const theme = {
    name: 'table-theme',
    tokens: {
      components: {
        table: {
          row: {
            hover: {
              backgroundColor: { value: '{colors.blue.20}' },
            },
  
            striped: {
              backgroundColor: { value: '{colors.blue.10}' },
            },
          },
  
          header: {
            color: { value: '{colors.blue.80}' },
            fontSize: { value: '{fontSizes.l}' }
          },
  
          data: {
            fontWeight: { value: '{fontWeights.semibold}' },
          },
        },
      },
    },
  };

export function withRouter(Children){
    return(props)=>{
       const match  = {params: useParams()};
       return <Children {...props}  match = {match}/>
   }
 }

class Opportunity extends React.Component {    
    constructor(props){
        super(props)
        this.state = {
            elements: [],
            lastModifiedDate: "",
            loading: true
        }        
    }      

    moveToHome() {        
        const homeUrl = `${window.location.origin}/`
        window.location.assign(homeUrl)
    }

    async componentDidMount() {
        this.setState({loading: true})
        const filepath = this.props.match.params.version + '/' + this.props.match.params.year + "/" + this.props.match.params.month + "/" 
                + this.props.match.params.day + "/" + this.props.match.params.file
        let response = await apiReportShitLiquidSymbolsGetTriangulation(filepath)
        this.setState({loading: false})
        if(!response.error){
            response = JSON.parse(response.payload.body)
            console.log(response)
            this.setState({lastModifiedDate: response.lastModifiedDate, elements: response.elements})
        }
    }

    render() {
        return (
            <>
            <View style={styles.container}>
                <Button style={styles.button} onClick={this.moveToHome}>Home</Button> 
                <Text>{this.state.lastModifiedDate}</Text>               
            </View>
            <View style={styles.table}>
                <ThemeProvider theme={theme} colorMode="light">
                    <Table highlightOnHover variation="bordered">
                        <TableHead>
                            <TableRow>
                                <TableCell as="th" colspan="1" rowspan="2">Recognizer</TableCell>
                                <TableCell as="th" colspan="3">First Trade</TableCell>
                                <TableCell as="th" colspan="3">Second Trade</TableCell>
                                <TableCell as="th" colspan="3">Third Trade</TableCell>
                                <TableCell as="th" colspan="2">Triangulation</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell as="th"><p>Symbol</p><p>Operation</p></TableCell>
                                <TableCell as="th">Prices</TableCell>
                                <TableCell as="th">OCO Params</TableCell>
                                <TableCell as="th"><p>Symbol</p><p>Operation</p></TableCell>
                                <TableCell as="th">Prices</TableCell>
                                <TableCell as="th">OCO Params</TableCell>
                                <TableCell as="th"><p>Symbol</p><p>Operation</p></TableCell>
                                <TableCell as="th">Prices</TableCell>
                                <TableCell as="th">OCO Params</TableCell>
                                <TableCell as="th">Gain Type</TableCell>
                                <TableCell as="th">Percent</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                            this.state.elements.map((item,index) => {
                            return (
                                <>
                                <TableRow key={index}>
                                    <TableCell rowspan="4"><p>{item.recognizedBy}</p><p>{item.changePercent}</p></TableCell>
                                    <TableCell rowspan="4"><p>{item.triangulations[0].common.symbol}</p><p>{item.triangulations[0].trade.operation}</p></TableCell>
                                    <TableCell>Price</TableCell>
                                    <TableCell>{toLinearString(item.triangulations[0].ocoParams.price)}</TableCell>
                                    <TableCell rowspan="4"><p>{item.triangulations[1].common.symbol}</p><p>{item.triangulations[1].trade.operation}</p></TableCell>
                                    <TableCell>Price</TableCell>
                                    <TableCell>{toLinearString(item.triangulations[1].ocoParams.price)}</TableCell>
                                    <TableCell rowspan="4"><p>{item.triangulations[2].common.symbol}</p><p>{item.triangulations[2].trade.operation}</p></TableCell>
                                    <TableCell>Price</TableCell>
                                    <TableCell>{toLinearString(item.triangulations[2].ocoParams.price)}</TableCell>
                                    <TableCell>Price Case</TableCell>                                     
                                    <TableCell>{toLinearString(item.diffPercentage)}</TableCell>
                                </TableRow>
                                <TableRow key={index + "_priceLimit"}>
                                    <TableCell>PriceLimit</TableCell>
                                    <TableCell>{toLinearString(item.triangulations[0].ocoParams.priceLimit)} {item.triangulations[0].ocoParams.priceLimitStatus}</TableCell>
                                    <TableCell>PriceLimit</TableCell>
                                    <TableCell>{toLinearString(item.triangulations[1].ocoParams.priceLimit)} {item.triangulations[1].ocoParams.priceLimitStatus}</TableCell>
                                    <TableCell>PriceLimit</TableCell>
                                    <TableCell>{toLinearString(item.triangulations[2].ocoParams.priceLimit)} {item.triangulations[2].ocoParams.priceLimitStatus}</TableCell> 
                                    <TableCell>Best Case</TableCell>
                                    <TableCell>{toLinearString(item.bestCaseDiffPercentage)}</TableCell>
                                </TableRow>
                                <TableRow key={index + "_stopLimit"}>
                                    <TableCell>StopLimit</TableCell>
                                    <TableCell>{toLinearString(item.triangulations[0].ocoParams.stopLimit)} {item.triangulations[0].ocoParams.stopLimitStatus}</TableCell>
                                    <TableCell>StopLimit</TableCell>
                                    <TableCell>{toLinearString(item.triangulations[1].ocoParams.stopLimit)} {item.triangulations[1].ocoParams.stopLimitStatus}</TableCell>
                                    <TableCell>StopLimit</TableCell>
                                    <TableCell>{toLinearString(item.triangulations[2].ocoParams.stopLimit)} {item.triangulations[2].ocoParams.stopLimitStatus}</TableCell>
                                    <TableCell>Worst Case</TableCell>
                                    <TableCell>{toLinearString(item.worstCaseDiffPercentage)}</TableCell>
                                </TableRow>
                                <TableRow key={index + "_stop"}>
                                    <TableCell>Stop</TableCell>
                                    <TableCell>{toLinearString(item.triangulations[0].ocoParams.stop)}</TableCell>
                                    <TableCell>Stop</TableCell>
                                    <TableCell>{toLinearString(item.triangulations[1].ocoParams.stop)}</TableCell>
                                    <TableCell>Stop</TableCell>
                                    <TableCell>{toLinearString(item.triangulations[2].ocoParams.stop)}</TableCell>
                                </TableRow>
                                </>
                            );
                        })} 
                        </TableBody>
                    </Table>  
                </ThemeProvider>                
            </View>            
            <Loader variation="linear" display={this.state.loading ? 'block' : 'none'}></Loader>
            </>
        )
    }
}

 export default withRouter(Opportunity)
 