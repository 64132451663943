import { Auth } from "aws-amplify"
import axios from "axios"

class ApiClient {
    constructor(){
        this.client = axios.create()
        this.#init()
    }

    #init(){
        this.client.interceptors.request.use(async (config) => {
            const token =  await this.#fetchToken()
            if (token !== null) {
                config.headers.Authorization = token 
            }        
            return config
        })
        this.client.interceptors.response.use(undefined, (error) => {
            return this.#onSessionExpired(error)
        })
    }

    /**
     * 
     * @returns {?string}
     */
    async #fetchToken () {
        let result = null
        await Auth.currentSession().then((data) => 
            result = data.getIdToken().getJwtToken()
        )
        .catch((err) => console.log(err))
        return result
    }

    #onSessionExpired (error) {
        if (error.response?.status === 401) {
            const logoutUrl = `${window.location.origin}/logout`
            window.location.assign(logoutUrl)
        }
        
        return Promise.reject(error)
    }   

    async doRequest (url, method = 'GET', payload = {}) {
        let apiResponse = null
        try {
            switch(method){
                case 'POST':
                    apiResponse = await this.client.post(url, {params: payload})
                break
                default:
                    apiResponse = await this.client.get(url, {params: payload})
            }
            return {error: (apiResponse.status !== 200), payload: apiResponse.data, errorMessage: ''}
        } catch(error){
            console.log(error)
            return {error: true, payload: {}, errorMessage: error.message}
        }
    }
}

export default ApiClient

