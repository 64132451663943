import { Link, Loader, View, Text } from "@aws-amplify/ui-react";
import React from "react";
import { apiReportShitLiquidSymbolsListMagic, apiReportShitLiquidSymbolsListTriangulation } from "../api/trades";

const styles = {
    container: { width: 400, margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
    table: { margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
    button: { backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' }
}

class HomeMagic extends React.Component { 

    constructor(props){
        super(props)
        this.state = {
            files: {},
            loading: true
        }
    }  

    async componentDidMount() {
        this.setState({loading: true})
        const response = await apiReportShitLiquidSymbolsListMagic(7)
        this.setState({loading: false})
        if(!response.error){
            this.setState({files: JSON.parse(response.payload.body)})
        }
    }

    moveToFile(file) {        
        const fileUrl = `${window.location.origin}/file?file=${file}`
        window.open(fileUrl, '_blank', 'noreferrer')
    }

    render() {
        let content = Object.keys(this.state.files).map((day, dayIndex) => 
            <View style={styles.table} key={dayIndex}>
                <Text key={day}>{day}</Text>
                {
                    this.state.files[day].map((item, index) =>
                        <Link key={item} color="#007EB9" href={`${window.location.origin}/magic/monitor/${item}`} isExternal={true}>{item}</Link>
                    )
                }
            </View>
        )
        return (
            <>
            <View style={styles.table}>
            <Loader variation="linear" display={this.state.loading ? 'block' : 'none'}></Loader>
            {content}  
            </View>
            </>
        )
    }
}

export default HomeMagic