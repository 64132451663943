import { Button, Text, Table, TableBody, TableCell, TableHead, TableRow, View, ThemeProvider, Loader } from "@aws-amplify/ui-react";
import React from "react";
import { useParams } from "react-router-dom";
import { apiReportShitLiquidSymbolsGetTriangulation } from "../api/trades";
import { getVersionPath } from "../hepers/helpers";
import { toLinearString } from "../utils/numbers";

const styles = {
    container: { width: 400, margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
    table: { margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
    button: { backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' }
}

const theme = {
    name: 'table-theme',
    tokens: {
      components: {
        table: {
          row: {
            hover: {
              backgroundColor: { value: '{colors.blue.20}' },
            },
  
            striped: {
              backgroundColor: { value: '{colors.blue.10}' },
            },
          },
  
          header: {
            color: { value: '{colors.blue.80}' },
            fontSize: { value: '{fontSizes.l}' }
          },
  
          data: {
            fontWeight: { value: '{fontWeights.semibold}' },
          },
        },
      },
    },
  };

export function withRouter(Children){
    return(props)=>{
       const match  = {params: useParams()};
       return <Children {...props}  match = {match}/>
   }
 }

class MonitorMagic extends React.Component {    
    constructor(props){
        super(props)
        this.state = {
            elements: [],
            lastModifiedDate: "",
            loading: true
        }        
    }      

    moveToHome() {        
        const homeUrl = `${window.location.origin}/magic/`
        window.location.assign(homeUrl)
    }

    async componentDidMount() {
        this.setState({loading: true})
        const filepath = this.props.match.params.version + '/' + this.props.match.params.year + "/" + this.props.match.params.month + "/" 
                + this.props.match.params.day + "/" + this.props.match.params.file
        let response = await apiReportShitLiquidSymbolsGetTriangulation(filepath)
        this.setState({loading: false})
        if(!response.error){
            response = JSON.parse(response.payload.body)
            console.log(response)
            this.setState({lastModifiedDate: response.lastModifiedDate, elements: response.elements})
        }
    }

    render() {
        return (
            <>
            <View style={styles.container}>
                <Button style={styles.button} onClick={this.moveToHome}>Home</Button> 
                <Text>{this.state.lastModifiedDate}</Text>               
            </View>
            <View style={styles.table}>
                <ThemeProvider theme={theme} colorMode="light">
                    <Table highlightOnHover variation="bordered">
                        <TableHead>
                            <TableRow>
                                <TableCell as="th" colspan="2">First Symbol</TableCell>
                                <TableCell as="th" colspan="2">Second Symbol</TableCell>
                                <TableCell as="th" colspan="2">Third Symbol</TableCell>
                                <TableCell as="th" colspan="2">Percentage</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell as="th">Symbol</TableCell>
                                <TableCell as="th">Price</TableCell>
                                <TableCell as="th">Symbol</TableCell>
                                <TableCell as="th">Price</TableCell>
                                <TableCell as="th">Symbol</TableCell>
                                <TableCell as="th">Price</TableCell>
                                <TableCell as="th">Inverse Perc.</TableCell>
                                <TableCell as="th">Straight Perc.</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                            this.state.elements.map((item,index) => {
                            return (
                                <>
                                <TableRow key={index}>
                                    <TableCell >{item.first.symbol}</TableCell>
                                    <TableCell>{toLinearString(item.first.price)}</TableCell>
                                    <TableCell >{item.second.symbol}</TableCell>
                                    <TableCell>{toLinearString(item.second.price)}</TableCell>
                                    <TableCell >{item.third.symbol}</TableCell>
                                    <TableCell>{toLinearString(item.third.price)}</TableCell>                  
                                    <TableCell>{toLinearString(item.inverseDiffPercentage)}</TableCell>                  
                                    <TableCell>{toLinearString(item.straightDiffPercentage)}</TableCell>
                                </TableRow>
                                </>
                            );
                        })} 
                        </TableBody>
                    </Table>  
                </ThemeProvider>                
            </View>            
            <Loader variation="linear" display={this.state.loading ? 'block' : 'none'}></Loader>
            </>
        )
    }
}

 export default withRouter(MonitorMagic)
 